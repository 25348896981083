@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://fonts.googleapis.com/css?family=Mansalva&display=swap";
@import "https://fonts.googleapis.com/css?family=Bangers&display=swap";
@import "https://fonts.googleapis.com/css?family=Baloo+Bhai|Fascinate+Inline|Liu+Jian+Mao+Cao|Pacifico|Srisakdi&display=swap";
@import "https://fonts.googleapis.com/css?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap";
.App-header{background-color:#1796e6eb;font-size:calc(10px + 2vmin);color:#fff;position:fixed;width:100%;left:0;top:0;z-index:9;height:4rem}
.App-header-nav{justify-content:space-between;align-items:center;flex:1;display:flex}
.App-logo{font-family:"Bangers",cursive;font-size:calc(12px + 2vmin);color:#fff}
.bangers{font-family:"Bangers",cursive;}
.App-nav{display:block}
.App-nav li{float:left;margin-left:10px;font-size:calc(10px + 1vmin)}
.App-nav li a{color:#fff;font-family:"Bangers",cursive}
.App-nav li a.active{color:#363636}
.App-contents{padding:0rem;height:calc(100vh - 8rem);box-sizing: border-box;margin-top: 4rem;margin-bottom: 4rem;}
.container-pd{padding:48px 0 58px}
.App-footer{position:relative;left:0;bottom:0;background-color:#2b2b2b;width:100%;height:4rem}
.App-footer-txt{color:#fff;padding:20px;font-size:12px}
.inner{max-width:1000px;width:100%;margin:0 auto}
.fl{float:left}
.fr{float:right}
.bgc-udim{background-color:#cf3721}

#tab-content{background-color:#fff;padding:20px 10px}
#tab-content p{display:none}
#tab-content p.is-active{display:block}
.bg-lightblue{background-color:#1796e6eb}
.introLogoTxt{font-size:20vw;line-height:20vw;font-weight:700;font-family:"Bangers",cursive;color:#fff}
.modalTitle{font-size:28px;font-weight:700;text-align:center}
.closeBtn{position:absolute;right:15px;top:15px}
.appname{font-family:"Bangers",'Noto Sans KR', sans-serif,cursive}
.contentWrapper{width:500px;height:85%;border:0 solid red;margin-top:10px}
.contentWrapper a,.contentWrapper div{display:flex;float:left;border:0 solid blue;width:50%;box-sizing:border-box;justify-content:center;align-items:center;flex-direction:column;text-align:center;margin-top:10px;font-weight:700;font-family: 'Noto Sans KR', sans-serif;}
.curp{cursor:pointer}
.w500px{width:500px}
.m0auto{margin:0 auto}
.portfolio-container{padding: 1rem;}

@media (max-width: 415px) {
  .introLogoTxt{font-size:8rem;line-height:8rem}
  .App-logo{font-size:3rem}
  .App-nav{display:block}
  .App-nav li{float:left;margin-left:10px;font-size:calc(10px + 1vmin)}
  .App-nav li a{color:#fff;font-family:"Bangers",cursive}
  .App-nav li a.active{color:#363636}
  .contentWrapper{width: 100%;}
  .contentWrapper a,.contentWrapper div{
    width: 100%;
    margin-bottom: 20px;
  }
}